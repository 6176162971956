<template>
  <b-modal
    id="quiz-question-modal"
    ref="question-modal"
    title="Thêm câu hỏi quiz"
    @show="openStaffPopup"
    @hidden="resetModal"
    @ok="submit"
  >
    <b-container fluid class="p-0">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col>
            <basic-input
              :required="true"
              label="Tiêu đề câu hỏi"
              name="vietnameseName"
              v-validate="'required'"
              :value.sync="form.vietnameseName"
              placeholder="Nhập tiêu đề câu hỏi"
            ></basic-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <basic-select
              label="TLoại câu hỏi"
              :options="[]"
              placeholder="--- Chọn loại câu hỏi ---"
              name="wards"
              :value.sync="form.emotionCategoryId"
              :solid="false"
              :allowEmpty="false"
            />
          </b-col>
        </b-row>
      </form>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-success ml-2"
          href="#"
          @click="submit"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AnswerModal',
  components: {},
  data() {
    return {
      form: {},
      rendered: false,
      validationState: {},
      error: {},
      filter: {},
    };
  },

  methods: {
    openStaffPopup() {},
    submit() {},
    resetModal() {},
  },
};
</script>

<style lang="scss">
#answer-modal {
  .modal-dialog {
    max-width: 550px;
  }
}
.label-required:after {
  content: '*';
  color: red;
}
</style>
